/* Vendor imports */
import React from 'react'
import { Link } from 'gatsby'
/* App imports */
import style from './footer.module.less'
import { Cookietext } from "../../../pages/cookies/Cookietext"



const getCookie = (name) => {
  if (typeof window !== 'undefined') {
    const value = " " + document.cookie;
    const parts = value.split(" " + name + "=");
    return parts.length < 2 ? undefined : parts.pop().split(";").shift();
  }
  return false;
};


const setCookie = function (name, value, expiryDays, domain, path, secure) {
  const exdate = new Date();
  exdate.setHours(
    exdate.getHours() +
    (typeof expiryDays !== "number" ? 365 : expiryDays) * 24
  );
  if (typeof window !== 'undefined') {
    document.cookie =
      name +
      "=" +
      value +
      ";expires=" +
      exdate.toUTCString() +
      ";path=" +
      (path || "/") +
      (domain ? ";domain=" + domain : "") +
      (secure ? ";secure" : "");
  }

};


const Footer = () => {

  const cookieName = "cookiesBanner";
  const [hasCookie, setHasCookie] = React.useState(getCookie(cookieName))


  const buttonClick = () => {
    setCookie(cookieName, "closed");
    setHasCookie(true);
  }


  return <div className={style.container}>
    <footer>
      <div className={style.footerLinks}>
        <ul>
          <li>|<Link to={"/impressum"}>Impressum
          </Link>
          </li>
          <li>|<Link to={"/datenschutz"}>Datenschutz
          </Link>
          </li>
          <li>|<Link to={"/cookies"}>Privatsphäre-Einstellungen
          </Link>
          </li>
          <li>|<a href="mailto:blog@365konten.de">Kontakt</a>|
          </li>
        </ul>
      </div>
    </footer>
    {!hasCookie &&
      <div className={style.cookiebanner} style={{ display: hasCookie ? "none" : "block" }}>
        <div className={style.cookiebannerContainer}>
          <h2>Privatsphäre-Einstellungen</h2>
          <Cookietext AllButtonClicked={buttonClick} CustomButtonClicked={buttonClick} />
        </div>
      </div>
    }
  </div>
}

export default Footer
